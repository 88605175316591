import io, { Socket } from 'socket.io-client';
import { DBaseUser } from 'spc/lib/database/types/base-user';
import { DUser } from 'spc/lib/database/types/user';

interface WebSocketParams {
  user: DUser | DBaseUser;
  handleSuccess: (verifiedData: { user: DUser | DBaseUser, token: string }) => void;
}

export class WebSocketService {
  constructor(private unwrapError) {
    'ngInject';
  }

  /**
   * Initiates a WebSocket connection for real-time user authentication.
   * @param {WebSocketParams} params - Object containing user details and success handler.
   */
  connectAuthWebSocket = ({ user, handleSuccess }: WebSocketParams) => {
    try {
      const userId = user._id ? user._id.toString() : '';

      if (!userId) {
        throw new Error('User ID is required to establish a WebSocket connection.');
      }

      const socket: Socket = this.createWebSocketConnection(userId);

      socket.on('connect', () => {
        this.handleLog(`Connected with socket ID: ${socket.id}`);
      });

      /**
       * Listens for the 'user-verified' event containing authentication details.
       */
      socket.off('user-verified').on('user-verified', (verifiedData) => {
        try {
          const { user: verifiedUser, token } = verifiedData;

          if (!verifiedUser || !token) {
            this.handleLog('Invalid verification data received.');
            return;
          }

          if (user._id !== verifiedUser._id.toString()) {
            this.handleLog('User ID mismatch. Ignoring verification.');
            return;
          }

          handleSuccess(verifiedData);
        } catch (error) {
          this.unwrapError(error);
        }
      });

      /**
       * Disconnects the socket if the maximum number of reconnection attempts is reached.
       */
      socket.on('reconnect_failed', () => {
        this.handleLog('WebSocket reconnection failed after maximum attempts.');
        socket.disconnect();
      });

      // Ensures WebSocket connection is properly closed.
      socket.off('disconnect').on('disconnect', () => {
        if (socket.connected) {
          this.disconnectSocket(socket);
        }
      });
    } catch (error) {
      this.unwrapError(error);
    }
  }

  /**
   * Creates and initializes a WebSocket connection with the given user ID.
   * @param {string} userId - The user's ID.
   * @returns {Socket} - The connected socket instance.
   */
  private createWebSocketConnection = (userId: string): Socket => {
    // To Be Removed - for testing
    // const WEBSOCKET_HOST_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:4000' : 'https://api.sixplusdemo.com'
    return io('https://api.sixplusdemo.com', {
      path: '/socket.io',
      transports: ['websocket'],
      secure: true,
      reconnectionAttempts: 5,
      query: { userId },
    });
  }

  /**
   * Handles logging in production-ready manner.
   * @param {string} message - Log message.
   */
  private handleLog(message: string): void {
    if (process.env.NODE_ENV !== 'production') {
      console.log(`[WebSocketService]: ${message}`);
    }
  }

  /**
   * Function to disconnects a given WebSocket instance. Ensures all event listeners are removed before closing the connection.
   * @param {Socket} socket - The WebSocket instance to disconnect.
   */
  private disconnectSocket = (socket: Socket) => {
    socket.off('user-verified');
    socket.off('disconnect');
    socket.disconnect();
    this.handleLog(`WebSocket disconnected`);
  }
}
