const facebook = require('facebook-oauth-agent');
const linkedin = require('linkedin-oauth-agent');
import { StateEmitter } from '../utils/StateEmitter';
import { get } from 'lodash';
// constant
import { ANALYTICS_EVENTS } from '../constants/ENUMS/analyticsEvents';
import { ApiService } from 'spc/shared/api/api.service';
import { ToastService } from 'spc/shared/toast.service';
import { AUTHENTICATION_TABS } from 'spc/constants/ENUMS/authenticationTabs';

module.exports = function() {
  return {
    template: require('./login.jade'),
    scope: {
      isEmbedded: '='
    },
    controller: ['$scope', '$user', 'ENUMS', '$timeout', '$analytics', 'spPardot', '$api', 'toast', function($scope, $user, ENUMS, $timeout, $analytics, spPardot, $api: ApiService, toast: ToastService) {
      $scope.AUTHENTICATION_TABS = AUTHENTICATION_TABS;
      $scope.user = $user;
      $scope.email = '';
      $scope.password = '';
      $scope.ui = {
        tab: $scope.AUTHENTICATION_TABS.PASSWORD,
        loading: false,
        resendCountdown: 0,
      };
      $scope.showPassword = false;
      $scope.resendTimeout;
      $scope.isBaseUser = false;

      const $ = $scope.$ =
        new StateEmitter(['INIT', 'SUCCESS', 'FAIL', 'LOGGED_IN', 'BASE_USER'], '');

      const userStates = $user.stream();
      userStates.on('LOGGED_IN', function() {
        if ($.$state() !== 'SUCCESS') {
          $.$state('LOGGED_IN');
          $scope.$apply();
        }
      });
      userStates.on('NOT_LOGGED_IN', function() {
        $.$state('INIT');
        $scope.$apply();
      });

      $scope.emitEvent = emitEvent;

      function emitEvent(eventName, params) {
        $scope.$emit(eventName, params);
      }

      function determineEvent () {
        const location = spPardot.determineLocation();
        return location ? `${ location } login` : 'login page';
      }

      function startResendCountdown (timer: number) {
        $scope.ui.resendCountdown = timer || $scope.ui.resendCountdown;

        if ($scope.ui.resendCountdown > 0) {
          $scope.ui.resendCountdown = $scope.ui.resendCountdown - 1;
          $scope.resendTimeout = $timeout(startResendCountdown, 1000);
        }
      }

      function cancelResendCountdown () {
        if ($scope.resendTimeout) {
          $timeout.cancel($scope.resendTimeout);
        }
      }

      function handleLoginError (error) {
        if (get(error, 'data.user')) {
          $.$state('BASE_USER');
          $scope.user = error.data.user;
          $scope.changeTabAndState({ tab: $scope.AUTHENTICATION_TABS.BASE_USER_REGISTRATION });
        } else {
          $scope.error = error.data.error;
          $.$state('FAIL');
        }
      }

      function handleLogin () {
        $scope.error = '';
        if ($.$state() === 'LOGGED_IN' || $.$state() === 'SUCCESS') {
          return;
        }
        // Analytics - clicked login
        const eventName = ANALYTICS_EVENTS.auth.clicked.login;
        $analytics.$trackEvent(eventName);

        $scope.handlePasswordManagers();
      }

      $scope.handleMagicLinkLogin = function (user?) {
        $scope.ui.loading = true;
        handleLogin();
        cancelResendCountdown();
        if (user) {
          $scope.email = get(user, 'profile.email');
        }

        return $user.magicLinkLogin({ email: $scope.email, onLoginSuccess: handleLoginSuccess })
          .then((response) => {
            if (!response) {
              return;
            }
            handleMagicLinkSuccess(response);
            $scope.isBaseUser = false;
          })
          .catch((error) => {
            handleLoginError(error);
          })
          .finally(() => {
            $scope.ui.loading = false;
          });
      };

      $scope.passwordLogin = function () {
        handleLogin();
        return $user.passwordLogin({ email: $scope.email, password: $scope.password })
          .then((response) => {
            if (!response) {
              return;
            }
            return handleLoginSuccess(response);
          })
          .catch(function (error) {
            handleLoginError(error);
          });
      };

      function handleLoginSuccess (response) {
        $.$state('SUCCESS');
        $scope.$emit('LOGIN_SUCCESS', response);
        spPardot.trackUser({ event: determineEvent() });
      }

      function handleRegisterSuccess (response) {
        $.$state('SUCCESS');
        $scope.$emit('REGISTER_SUCCESS', response);
        spPardot.trackUser({ event: determineEvent() });
      }

      function handleMagicLinkSuccess (response) {
        $scope.user = response.user;
        $scope.changeTabAndState({ tab: $scope.AUTHENTICATION_TABS.MAGIC_LINK_SUCCESS });
        startResendCountdown(30);
        toast.goodNews('Success', 'A new magic link has been sent. Please check your inbox.');
      }

      $scope.handleMagicLinkRegister = function () {
        $scope.ui.loading = true;
        $scope.error = '';
        cancelResendCountdown();

        return $user.magicLinkRegister({ user: $scope.user, onRegisterSuccess: handleRegisterSuccess })
        .then((response) => {
          handleMagicLinkSuccess(response);
          $scope.isBaseUser = true;
        })
        .catch((error) => {
          $scope.error = error.data.error;
        })
        .finally(() => {
          $scope.ui.loading = false;
        });
      };

      $scope.sendPasswordSetupLink = function () {
        $scope.ui.loading = true;
        $scope.error = '';

        return $api.Auth.setupPasswordWithLink($scope.user.profile.email)
          .then(() => {
            $scope.changeTabAndState({ tab: $scope.AUTHENTICATION_TABS.PASSWORD_SETUP_LINK_SUCCESS });
            toast.goodNews('Success', 'Password setup registration link has been sent to your email.');
          })
          .catch((error) => {
            $scope.error = error.data.error;
          })
          .finally(() => {
            $scope.ui.loading = false;
          });
      };

      $scope.handleMagicLinkResend = function () {
        if ($scope.isBaseUser) {
          $scope.handleMagicLinkRegister();
        } else {
          $scope.handleMagicLinkLogin($scope.user);
        }
      };

      $scope.linkedinLogin = function() {
        $user.linkedinLogin(function(error, res) {
          if (!error) {
            spPardot.trackUser({ event: determineEvent() });
            $scope.$emit(res.isNew ? 'REGISTER_SUCCESS' : 'LOGIN_SUCCESS',
              res);
          }
        });
      };

      $scope.facebookLogin = function() {
        $user.facebookLogin(function(error, res) {
          if (!error) {
            spPardot.trackUser({ event: determineEvent() });
            $scope.$emit(res.isNew ? 'REGISTER_SUCCESS' : 'LOGIN_SUCCESS',
              res);
          }
        });
      };

      $timeout(function() {
        $scope.$emit('LoginController');
      }, 0);

      $scope.changeTabAndState = function ({ tab, state }) {
        if (state) {
          $.$state(state);
        }
        $scope.error = '';
        $scope.ui.tab = tab;
      };

      $scope.toggleShowPassword = function () {
        return $scope.showPassword = !$scope.showPassword;
      };
    }],
    link: function(scope, element, attrs) {
      scope.handlePasswordManagers = function () {
        scope.email = element.find('#loginEmail').val();
        scope.password = element.find('#loginPassword').val();
      };
    }
  };
};
