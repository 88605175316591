import { get } from 'lodash';
import { ApiService } from 'spc/shared/api/api.service';

class MagicLinkVerificationController {
  token: string;
  operation: string;
  loading: boolean;
  error: string = '';

  constructor(
    private $api: ApiService,
    private $user,
    private unwrapError,
    private $location,
    private $routeParams
  ) {
    'ngInject';
  }

  $onInit = () => {
    this.operation = this.$routeParams.operation;
    this.token = this.$routeParams.token;

    if (!this.token || !this.operation) {
      this.error = 'Oops! It looks like the link has expired or is invalid. Please request a new one to continue.';
      return;
    }

    return this.verifyUserMagicToken();
  }

  verifyUserMagicToken = () => {
    this.loading = true;
    this.error = '';

    // Verifying magic link token
    return this.$api.Auth.verifyMagicLinkToken({ token: this.token, operation: this.operation })
      .then(({ data: authData }) => {
        if (authData.operation === 'register') {
          return this.handleUserRegistration({ user: authData.user });
        } else if (authData.operation === 'login') {
          return this.handleUserLogin(authData);
        }
      })
      .catch((error) => {
        this.loading = false;
        this.error = get(error, 'data.message');
        this.unwrapError(error);
      });
  }

  handleUserLogin = ({ user, token }) => {
    this.loading = false;
    this.$user.setUser(user, token);
    this.$location.url('/');
  }

  handleUserRegistration = ({ user }) => {
    const userData = {
      guest: user,
      isMagicLinkRegistration: true,
      password: ''
    };

    return this.$api.Auth.register(userData)
      .then(({ data }) => {
        this.loading = false;
        this.$user.setUser(data.user, data.token);
        this.$location.url('/welcome');
      })
      .catch((error) => {
        this.unwrapError(error);
      });
  }
}

export const MagicLinkVerification = {
  template: require('./magic-link-verification.jade'),
  controller: MagicLinkVerificationController
};
